<template>
    <div>
      <!-- Table Container Card -->
      <b-modal
          :ref="`my-modal`"
          centered
          ok-only
          ok-title="حسنا"
          cancel-variant="outline-secondary"
          title-tag="div"
        >

<b-row>
  <b-col md="12"  >
<h6>الملاحظة: </h6>
<p >{{data}}</p>
  </b-col>

</b-row>
          
        </b-modal>
      <b-card no-body class="mb-0">
      
    
  
        <b-table
          ref="reftransferListTable"
          class="position-relative h-100"
          :items="fetchListrequestAuditing"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
        
        <template #cell(person)="data" >
          {{data.item.person.personal_info? data.item.person.personal_info.first_name + "  " + data.item.person.personal_info.last_name : data.item.person.first_name + "  " + data.item.person.last_name}}
          <!-- <span >{{ data.item.person.personal_info.first_name + " " +  data.item.person.personal_info.last_name}}</span> -->
          

        </template>
        <template #cell(service)="data" >
          {{data.item.person.personal_info? "فرد" : "عائلة"}}
          <!-- <span >{{ data.item.person.personal_info.first_name + " " +  data.item.person.personal_info.last_name}}</span> -->
          

        </template>
        <template #cell(notes_case_manager)="data" >

         <div class="d-flex justify-content-center">
        <feather-icon icon="EyeIcon" class="mr-50" @click="showEmpModal(data.item.notes_case_manager)" />
    </div>
        </template>
      
          <template #cell(notes)="data" >
            <b-form-textarea
           
            id="helperInput"
  
            placeholder="ملاحظة"
            v-model="note"
          />
        </template>
          <template #cell(actions)="data"    v-if=" role.roles.some((el) => el['name'] === 'coordinator') ">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item  @click="responseByCoordinator(data.item.id,1,note)">
  
                     <feather-icon icon="CheckIcon" />
                <span class="align-middle ml-50"> موافق </span>
              </b-dropdown-item>
              <b-dropdown-item   @click="responseByCoordinator(data.item.id,0,note)">
  
  <feather-icon icon="XIcon" />
  <span class="align-middle ml-50"> رفض </span>
  </b-dropdown-item>

  
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalUsers"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
        <rejecte-reason ref="childComponent" @fetchData="refetchData"></rejecte-reason>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BPagination,
    BCardHeader,
    BFormTextarea,
    BCardBody,
    BFormInvalidFeedback,
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  import store from "@/store";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import { ref, onUnmounted } from "@vue/composition-api";
  import { avatarText } from "@core/utils/filter";
  import ServicesListFilters from "./ServicesListFilters.vue";
  import requestAuditing from "./requestAuditing";
  import { max } from "@validations";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import servicesStoreModule from "./servicesStoreModule";
  import { jsontoexcel } from "vue-table-to-excel";
  import RejecteReason from "../ServiceTransfer/RejecteReason.vue";
  export default {
    setup() {
      const Services_APP_STORE_MODULE_NAME = "app-services";
  
      // Register module
      if (!store.hasModule(Services_APP_STORE_MODULE_NAME))
        store.registerModule(Services_APP_STORE_MODULE_NAME, servicesStoreModule);
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(Services_APP_STORE_MODULE_NAME))
          store.unregisterModule(Services_APP_STORE_MODULE_NAME);
      });
  const subServiceId= ref('');
  
  const TransferTypeOptions=ref([{
      label:'صادرة',
      value:'outgoing'
  },
  {
      label:'واردة',
      value:'incoming'
  }
  ])
  
         const subServiceOption = ref([]);
  const employOption=ref([])
  
       const getSubService = (id) => {
          //console.log(id)
  
        store.dispatch("app-services/SubService",id).then((response) => {
  
          subServiceOption.value = response.data.data;
  
        });
  
      };
      const getEmployy = (id) => {
          //console.log(id)
  
        store.dispatch("app-services/getEmployy",id).then((response) => {
          //console.log('em',response)
          employOption.value = response.data;
  
        });
  
      };
      const serviceId = localStorage.getItem("roleService");
  
      const role = JSON.parse(localStorage.getItem("userData"));
      //console.log(role)
              if(serviceId){
              getSubService(serviceId)
              getEmployy(serviceId)
  
          }
      const MainService = () => {
  
  store.dispatch("app-services/GetMainService").then((response) => {
      //console.log(response)
      mainServiceOption.value = response
  
  });
  
  };
  MainService()
  
      const {
        fetchListrequestAuditing,
        tableColumns,
        responseByCoordinator,
  
        perPage,
        currentPage,
        totalUsers,
        mainService,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        reftransferListTable,
        refetchData,
        mainservice,
        mainServiceOption,
        TransferType
  
  
      } = requestAuditing();
  
      return {
          mainservice,
          role,
          responseByCoordinator,
          
          mainServiceOption,
          subServiceId,
          MainService,
          mainService,
        fetchListrequestAuditing,
        tableColumns,
      
        subServiceOption,
  serviceId,
        perPage,
        currentPage,
        totalUsers,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        reftransferListTable,
        refetchData,
        TransferTypeOptions,
        TransferType,
        employOption
      };
    },
    
  data() {
    return {
      data:'',
      note:'',
    }},
    components: {
      ServicesListFilters,
      flatPickr,
      BFormTextarea,
      BButton,
      max,
      BDropdownItemButton,
      BFormInvalidFeedback,
      ValidationProvider,
      ValidationObserver,
      BCard,
      BRow,
      BCol,
      BFormInput,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      BCardHeader,
      BCardBody,
      vSelect,
      RejecteReason
  },

  methods: {
    showEmpModal(notes) {
    
      this.data = notes;
      this.$refs["my-modal"].show();
    },
  },
   
  
  
  };
  </script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>
  <style scoped>
  div {
    color: #7e7a9f;
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .media {
    color: #7e7a9f;
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  </style>
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
  