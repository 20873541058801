<template>
  <b-card no-body>
    <b-card-header class="pb-50"> </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>الجنس</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="gender"
            :options="genderOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:gender', val)"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2" v-if="role=='information_manager'
        || role=='Managers'">
       
            <label> الخدمة</label>
            <v-select
                 :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
             :value="mainService"
              
              :reduce="(val) => val.value"
              :options="optionMainService"
                @input="(val) => $emit('update:mainService', val)"
          
            />
      
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>الخدمات الفرعية</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="serviceFilter"
            :options="serviceOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:serviceFilter', val)"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>حالة الخدمة</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label> المنطقة</label>
          <v-select
            :value="city"
            :options="CityOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:city', val) && getOCitVillage(val)"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label> الناحية</label>
          <v-select
            :value="town"
            :options="VillageOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:town', val) && getOCovTown(val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label> الحي</label> 
          <v-select
            :value="neighborhood"
            :options="TownOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:neighborhood', val) && getOCovStreet(val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label> تفاصيل الحي</label>
          <v-select
            :value="street"
            :options="StreetOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:street', val)"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label> طبيعة العائلة</label>
       
          <v-select
            :value="familyStatus"
            :options="FamilyOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:familyStatus', val)"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label> نقاط الضعف</label>
          <treeselect
            :multiple="true"
            :options="optionsWeak"
            :value="multi_weakness"
             @input="handleWeaknessChange"
          />
          
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default {
  components: {
    BRow,
    Treeselect,
    BFormInput,
    flatPickr,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BFormGroup,
  },

  props: {
    gender: {
      type: [Number, ""],
      default: "",
    },
    familyStatus: {
      type: [Number, null],
      default: null,
    },

    city: {
      type: [Number, null],
      default: null,
    },
    multi_weakness: {
      type: [Array, []],
      default: [],
    },
    neighborhood: {
      type: [Number, null],
      default: null,
    },

    town: {
      type: [Number, null],
      default: null,
    },
    street: {
      type: [Number, null],
      default: null,
    },

    planFilter: {
      type: [Number, null],
      default: null,
    },
    statusFilter: {
      type: [Number, null],
      default: null,
    },

    serviceFilter: {
      type: [Number, null],
      default: null,
    },
     mainService: {
      type: [Number, null],
      default: null,
    },

    genderOptions: {
      type: Array,
      required: true,
    },

    statusOptions: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      serviceOptions: [],
      FamilyOptions: [],
      CityOptions: [],
      TownOptions: [],
     StreetOptions:[],
      VillageOptions:[],
      optionsWeak: [],
      optionMainService: [],
      // mainService:'',
  
      role: "",
    };
  },
  watch: {
    'mainService': function () {
     
      this.serviceOptions = [];
      this.serviceFilter=null;
      let url = `/api/v1/main_services/${this.mainService}/proposal_services`;
      this.$http.get(url).then((res) => {
       
        let subServices = res.data.data;
        subServices.forEach((el) => {
          this.serviceOptions.push({ label: el.name, value: el.id });
        });
      });
    },
  },

  created() {
    let userData = JSON.parse(localStorage.getItem("userData"));
    this.role = userData.roles[0].name;

    let serviceId = localStorage.getItem("roleService");
    if(serviceId){
    let url = `/api/v1/main_services/${serviceId}/proposal_services`;
    this.$http.get(url).then((res) => {
      //console.log(res);
      let subServices = res.data.data;
      subServices.forEach((el) => {
        this.serviceOptions.push({ label: el.name, value: el.id });
      });
    });}
    this.getOCovCit();
    this.getFamilyStatus();
    this.getWeak();
    this.getServiceType();
  },

  methods: {
    handleWeaknessChange(val) {  
    // Update the multi_weakness prop  
    // this.$emit('update:multi_weakness', val);  
    
    // Logic to handle selection and deselection of sub-weaknesses  
    const selected = new Set(val);  
    
    // If a main weakness is selected, include its sub-weaknesses  
    this.optionsWeak.forEach(main => {  
      if (selected.has(main.id)) {  
        main.children.forEach(sub => selected.add(sub.id));  
      }  
    });  

    // If a main weakness is unselected, remove its sub-weaknesses  
    // this.optionsWeak.forEach(main => {  
    //   if (!selected.has(main.id)) {  
    //     main.children.forEach(sub => selected.delete(sub.id));  
    //   }  
    // });  

    // Convert the set back to an array and emit it  
    this.$emit('update:multi_weakness', Array.from(selected));  
  }  ,
    getWeak() {
      this.optionsWeak = [];
      this.$http.get("/api/v1/beneficiaries_page/get_dropdown_data").then((res) => {
        let subweak = res.data.data.sub_weaknesses;
        let Weak = res.data.data.weaknesses;

        Weak.forEach((el) => {
          let obj = {};
          obj.children = [];
          obj.label = el.name;
          obj.id = el.id;

          subweak.forEach((ele, index) => {
            if (el.id == ele.parent_id) {
              obj.children.push({ label: subweak[index].name, id: ele.id });
            }
          });
          this.optionsWeak.push(obj);
        });
      });
    },
    async getOCovCit() {
      this.CityOptions = [];
      let url = `/api/v1/governorates/8/cities`;
      await this.$http.get(url).then((response) => {
        let Cities = response.data.data;

        Cities.forEach((el) => {
          this.CityOptions.push({ label: el.name, value: el.id });
        });
      });
    },

    async getOCovTown(val) {
    this.TownOptions = [];

      let url = `/api/v1/towns/${val}/neighborhoods`;
      this.$http.get(url).then((response) => {
        let towns = response.data.data;

        towns.forEach((el) => {
          this.TownOptions.push({ label: el.name, value: el.id });
        });
      });
    },
        async getOCovStreet(val) {
      this.StreetOptions = []

      const url = `/api/v1/neighborhoods/${val}/streets`
      this.$http.get(url).then(response => {
        const towns = response.data.data

        towns.forEach(el => {
          this.StreetOptions.push({ label: el.name, value: el.id })
        })
        console.log(this.StreetOptions)
      })
    },
    async getOCitVillage(id) {
      this.VillageOptions = [];
      let url = `/api/v1/cities/${id}/towns`;
      await this.$http.get(url).then((response) => {
        let village = response.data.data;
        //console.log(village);
        village.forEach((el) => {
          this.VillageOptions.push({ label: el.name, value: el.id });
        });
      });
    },
    getServiceType() {
      this.$http.get("/api/v1/beneficiaries_page/get_dropdown_data").then((res) => {
        let proposal_services = res.data.data.main_services;
        //console.log(res.data.data.main_services)
        proposal_services.forEach((el) => {
          this.optionMainService.push({ label: el.name, value: el.id });
        });
      });
    },
    getFamilyStatus() {
      this.$http.get("/api/v1/family_statuses").then((res) => {
        let FamilyOptions = res.data.data;

        FamilyOptions.forEach((el) => {
          this.FamilyOptions.push({ value: el.id, label: el.name });
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>