import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";
import Vue from "vue";
// Notification

import axios from "@axios";
import { AlignCenterIcon } from "vue-feather-icons";

export default function requestAuditing() {





  const reftransferListTable=ref(null)
  const mainServiceList=ref('')
  const mainServiceOption=ref([])
  // Table Handlers
  const tableColumns = [
    { key: "id", label: " الرقم",formatter: title,},
    
    { key: "person", label: "الفرد",formatter: title,},
    { key: "service", label: "الخدمة",formatter: title,},
    {
        isActive: false, 
        key: "service_status",
        label: "الحالة",
        formatter: title,
  
      },
      {
        isActive: false, 
        key: "name_case_manager",
        label: "مدير الحالة",
        formatter: title,
  
      },
    {
      isActive: false, 
      key: "notes_case_manager",
      label: "ملاحظات مدير الحالة ",
      formatter: title,
      

    },
    
    {
      isActive: false, 
      key: "notes",
      label: " الملاحظات",
      formatter: title,

    },

    { key: "actions", label: "الخيارات" },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const TransferType=ref('outgoing')
  const dataMeta = computed(() => {
    const localItemsCount = reftransferListTable.value
      ? reftransferListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    reftransferListTable.value.refresh();
  };
  watch(
    [
        TransferType,
      currentPage,
      perPage,
      searchQuery,

    ],
    () => {
      refetchData();
    }
  );

  const responseByCoordinator= (id, statuts,notes) => {
 
    const text = statuts == 1? 'تمت الموافقة بنجاخ' : 'تم الرفض بنجاح';
    // }else{
    store
      .dispatch("app-services/responseByCoordinator",{
        
        approval_status:statuts,
        id:id,
        notes:notes
      

      })
      .then((response) => {
        refetchData()
        Vue.swal({
            title: text,
      
            icon: "success",
            confirmButtonText: "موافق",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          
      })
      .catch(() => {
        Vue.swal({
            title: `${response.data.message}`,
      icon: "error",

        confirmButtonText: "موافق",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      });
    // }
  };
  const fetchListrequestAuditing = (ctx, callback) => {
    let userData= JSON.parse(localStorage.getItem("userData"));
    //console.log("userData",userData.roles[0].id)
    
    // }else{
    store
      .dispatch("app-services/fetchListrequestAuditing",{
        
        page: currentPage.value,
        page_size:perPage.value,
      

      })
      .then((response) => {
        const { data, total } = response.data;
        //console.log(response.data)
        callback(data);
        totalUsers.value = total;
      })
      .catch(() => {
        Vue.swal({
              title: "حدثت مشكلة في استرجاع البيانات",
      icon: "error",

        confirmButtonText: "موافق",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      });
    // }
  };





  return {
    fetchListrequestAuditing,
    mainServiceOption,
    responseByCoordinator,
    mainServiceList,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,

    reftransferListTable,
    TransferType,

    refetchData,

  };
}
