// We haven't added icon's computed property because it makes this mixin coupled with UI
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export const personDetails = {
  
  data() {
    return {
      phones: [
        {
          number: "",
          notes: "",
          contact_type: "",
        },
      ],
      itemsWekid:[],
      optionService:[],
      documents: [
        {
          release_date: "",
          expiration_date: "",
          number: null,
          document_type_id: null,
          notes: "",
        },
      ],
      weaknesses: [
        {
            weakness_id:'',
            notes:""
        }
        ],
        services: [
          {
          
            proposal_service_id: "",
            notes: "",
          },
        ],
        fieldsDoc: [ { key: 'name', label: 'الوثيقة' }, { key: 'number', label: 'الرقم' },{ key: "notes", label: "الملاحظة" },],

        itemsDoc: [
       ],
       fieldsNum: [ { key: 'name', label: 'الوسيلة' }, { key: 'number', label: 'الرقم' },{ key: "notes", label: "الملاحظة" },],

       itemsNum: [
      ],
      
      fieldsWek: [ { key: 'name', label: 'النقطة' }],

      itemsWek: [
     ],
     fieldsServ: [ { key: 'main', label: ' الخدمة الأساسية' },
     { key: 'sub', label: ' الخدمة الفرعية' },
     { key: 'notes', label: 'الملاحظة' },
    ],
     itemsServ:[],
        services: [
          {
          
            proposal_service_id: "",
            notes: "",
            Mainservice:''
          },
        ],
        allweak:[],
        allServ:[],
        allServId:[],
        optionTo: [],
        optionDocuments: [],
        optionsWeak: [],
        contactType: [],
        allphones:[],
        optionWeakness: [],
        EducationalState: [],
  
        optiongender: [],
      
        optionNationality: [],
        optionStudy: [],
        optionRelationship: [],
        MartialState: [],
    };
  },
  methods: {
    addServ(){
      return new Promise((resolve, reject) => {
        this.$refs.Personservices.validate().then((success) => {
          if(success){
         //   console.log(this.services)
       this.services.forEach(el =>{
       this.itemsServ.push({main:el.MainService.label ,sub:el.proposal_service_id.label,notes:el.notes})
       this.allServId.push()
    this.allServ.push(el)
    
    })
    // console.log(this.itemsServ)
    this.resetModalServ()
    
        }
     
    
    
    })
    
    })


    },
    addWeak(){
      return new Promise((resolve, reject) => {
        this.$refs.personweakness.validate().then((success) => {
          if(success){
   
       this.weaknesses.forEach(el =>{
     //console.log("S", this.weaknesses)
         this.optionsWeak.forEach(ele=>{
         
           if(ele.id==el.weakness_id ){
           
             if(this.itemsWekid.includes(el.weakness_id )){
              this.$swal({
              
                text: "النقطة مضافة بالفعل",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,

              });
              return
                      }
                     
                      else{

       
           
          this.itemsWek.push({name:ele.label})  
            this.itemsWekid.push(el.weakness_id)
            
            this.allweak.push(el)
          
                 

          }
        }
       
          else{
          ele.children.forEach(element=>{
            if(element.id==el.weakness_id ){
              if(this.itemsWekid.includes(el.weakness_id )){
                this.$swal({
                
                  text: "النقطة مضافة بالفعل",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
                        }
                        else{
  
            this.itemsWek.push({name:element.label})
            this.itemsWekid.push(el.weakness_id)}
           
            this.allweak.push(el)
          }
           })}
           
         },
     
         
         
         )
        
         
    })
    this.resetModalWek()
    
        }
     
    
    
    })
    
    })
    },
    addNumberr(){
      return new Promise((resolve, reject) => {
    this.$refs.phonesimple.validate().then((success) => {
      if(success){
   this.phones.forEach(el =>{
    
    
this.itemsNum.push({name:el.contact_type.name,number:el.number,notes:el.notes})
this.allphones.push(el)
})
this.resetModalNum()

    }
 


})

})
  
},
    deleteNumber(index) {
      this.phones.splice(index, 1);
    },
    deleteDocument(index) {
      this.documents.splice(index, 1);
    },
    deleteWeak(index){
      this.weaknesses.splice(index, 1);
    },
    resetModalWek() {
      this. weaknesses= [
        {
            weakness_id:'',
            notes:""
        }
        ]
            requestAnimationFrame(() => {
         this.$refs.personweakness.reset();
       });
     },
     resetModalServ() {
      this.services= [
        {
          proposal_service_id: "",
            notes: "",
            Mainservice:""
        },
      ],
      requestAnimationFrame(() => {
        this.$refs.Personservices.reset();
      });
    
    },
     resetModalNum() {
      this.phones= [
        {
          number: "",
          notes: "",
          contact_type: "",
        },
      ],
            requestAnimationFrame(() => {
         this.$refs.phonesimple.reset();
       });
     },
    resetModalDoc() {
      this.documents= [
         {
           release_date: "",
           expiration_date: "",
           number: null,
           document_type_id: null,
           notes: "",
         },]
            requestAnimationFrame(() => {
         this.$refs.documentsimple.reset();
       });
     },
      addWeaknesstoPer() {
      this.allweak.forEach((el) => {
        this.Personweakness.push({ weakness_id: el.weakness_id, notes: el.notes });
      });
      this.allweak=[]
      
       this.itemsWekid=[]
      this.saveWeakness = true;
      this.$refs["modal-weakness"].hide();
    },
    addServicestoPer() {
      this.allServ.forEach((el) => {
        this.Personservices.push({proposal_service_id: el.proposal_service_id.value, notes: el.notes });
      });
      console.log( this.Personservices)
      this.allServ=[]
      
       this.allServId=[]
      this.saveServices = true;
      this.$refs["modal-services"].hide();
    },
    addDocumenttoPer() {
      this.alldocuments.forEach((el) => {
        this.PersonDocuments.push({
          document_type: el.document_type.id,
          release_date: el.release_date,
          expiration_date: el.expiration_date,
          notes: el.notes,
          number: el.number,
        });
      });

      this.saveDocuments = true;
      this.$refs["modal-documents"].hide();
    },
    addNumbertoPerson() {
      this.allphones.forEach((el) => {
        this.Personphones.push({
          contact_type: el.contact_type.id,
          notes: el.notes,
          number: el.number,
        });
      });
      this.saveNumbers = true;

      this.$refs["my-modal"].hide();
    },
    addWekPerson() {
      this.$refs["modal-weakness"].show();
    },
    addServPerson() {
      this.$refs["modal-services"].show();
    },
    addDocPerson() {
      this.$refs["modal-documents"].show();
    },
    addNumPerson() {
      this.$refs["my-modal"].show();
    },

    adddocument(){
               return new Promise((resolve, reject) => {
        this.$refs.documentsimple.validate().then((success) => {
             if(success){
            
    this.documents.forEach(el =>{
this.itemsDoc.push({name:el.document_type.name,number:el.number,notes:el.notes})
// if(el.document_type.name=="لا توجد وثائق"){
//  this.optionDocuments=[]
// }
  this.alldocuments.push(el)
  })
  this.resetModalDoc()

  resolve(true)
        }
        else{
        
        reject();
        }
})})

},
    UpdateNumber(id) {
    
      let data = null;
      
      this.phones.forEach((el) => {
        data = {
          number: el.number,
          note: el.notes,
          contact_type: el.contact_type,
        };
      });

      this.$http.put("/api/v1/pending_actions/contact_details/" + id, data).then((res) => {

        
 this.$swal({
                  title: "",
                  text: "تم طلب التعديل بانتظار موافقة مدير المعلومات",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });

      });
    },
    UpdateDocument(id) {
      let data = null;
  
      this.documents.forEach((el) => {
        data = {
          number: el.number,
          note: el.notes,
          release_date: el.release_date,
          expiration_date: el.expiration_date,
          document_type: el.document_type,
        };
      });

      let url = `/api/v1/pending_actions/person_documents/${id}`;
      this.$http.put(url, data).then((res) => {
   
            
 this.$swal({
                  title: "",
                  text: "تم طلب التعديل بانتظار موافقة مدير المعلومات",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
      });
    },
    anotherservice() {
      this.services.push({
  
        proposal_service_id: "",
        notes: "",
        Mainservice:''
      });
    },
    removeservice(index) {
      this.services.splice(index, 1);
    },
    getWeak() {
      this.optionsWeak = [];
      this.$http.get("/api/v1/beneficiaries_page/get_dropdown_data").then((res) => {
        let subweak = res.data.data.sub_weaknesses;
        let Weak = res.data.data.weaknesses;

        Weak.forEach((el) => {
          let obj = {};
          obj.children = [];
          obj.label = el.name;
          obj.id = el.id;

          subweak.forEach((ele, index) => {
            if (el.id == ele.parent_id) {
              obj.children.push({ label: subweak[index].name, id: ele.id });
            }
          });
          this.optionsWeak.push(obj);
        });
      });
    },
    getAlldrowpDown() {
      this.$http.get("/api/v1/beneficiaries_page/get_dropdown_data").then((res) => {
      
        let proposal_services = res.data.data.main_services;
        proposal_services.forEach((el) => {
          this.optionService.push({ label: el.name, value: el.id });});
        let contactType = res.data.data.contact_types;
        contactType.forEach((el) => {
          this.contactType.push({ name: el.name, id: el.id });
        });
        let gender = res.data.data.genders;
        gender.forEach((el) => {
          this.optiongender.push({ label: el.name, value: el.id });
        });
        let countries = res.data.data.countries;
        countries.forEach((el) => {
          this.optionNationality.push({ label: el.name, value: el.id });
        });
        let educational_statuses = res.data.data.educational_statuses;
        educational_statuses.forEach((el) => {
          this.EducationalState.push({ label: el.name, value: el.id });
        });
        let martial_statuses = res.data.data.martial_statuses;
        martial_statuses.forEach((el) => {
          this.MartialState.push({ label: el.name, value: el.id });
        });
        let registration_statuses = res.data.data.registration_statuses;
        registration_statuses.forEach((el) => {
          this.RegistrationStatuses.push({ label: el.name, value: el.id });
        });
        let person_document_types = res.data.data.person_document_types;

        person_document_types.forEach((el) => {
          this.optionDocuments.push({ name: el.name, id: el.id });
        });
        let educational_attainments = res.data.data.educational_attainments;
        educational_attainments.forEach((el) => {
          this.optionStudy.push({ label: el.name, value: el.id });
        });
        let family_relationships = res.data.data.family_relationships;

        family_relationships.forEach((el) => {
          this.optionRelationship.push({ label: el.name, value: el.id });
        });
      });
    },
  },
};

export const _ = null;
